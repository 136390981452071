<style scoped>
.back {
  padding: 3px 10px;
  width: 100%;
  height: 44px;
  background: white;
  font-weight: bold;

  line-height: 44px;
  display: flex;
  position: relative;
  text-align: center;
  color: #5a5f69;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.list {
  width: 100%;
  margin: 18px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 2rem;
  line-height: 2rem;
}

.list .listTitle {
  text-align: right;
  margin-right: 0.5rem;
  width: 100px;
  color: #aaa;
}

.userInfoinput {
  border: 1px solid #f1eeee;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  line-height: 2rem;
  border-radius: 5px;
  padding-left: 0.3125rem;
  margin-left: 0.46875rem;
  color: #695f5f;
  font-weight: bold;
  padding: 0 10px;
  outline: none;
}

.friendAvatar {
  width: 16vw;
  height: 16vw;
  background: #dadada;
  position: relative;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 16px;

  vertical-align: middle;
  display: inline-block;
  line-height: 16vw;
  border-radius: 5px;
  padding-left: 0.3125rem;
  margin-left: 0.46875rem;
  color: #695f5f;
  font-weight: bold;
  padding: 0 10px;
  outline: none;
}

.userInfoinput::placeholder {
  font-weight: 300;
}

input[disabled] {
  background: rgba(199, 210, 214, 0.1);
  color: #ccc;
}

select {
  color: #695f5f;
  font-weight: bold;
  padding-left: 0.3125rem;
  margin-left: 0.46875rem;
  font-size: 1rem;
  line-height: 2rem;
  font-family: inherit;
  letter-spacing: inherit;
  text-transform: none;
  border: 1px solid #f1eeee;
  outline: none;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* background-color: transparent; */
  /* border-color: transparent; */

  -webkit-appearance: none;
}

select option:disabled {
  color: #ccc;
}
</style>
<template>
  <div>
    <div class="back">
      <span style="position: absolute; left: 10px; top: 9px" @click="back">
        <svg
          t="1645171851221"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          p-id="81479"
          width="30"
          height="30"
        >
          <path
            d="M475 276V141.4c-12.1-56.3-58.2-22-58.2-22L96.6 395.9c-70.4 48.9-4.8 85.7-4.8 85.7l315.4 274.1c63.1 46.5 67.9-24.5 67.9-24.5V606.4C795.3 506 926.3 907.5 926.3 907.5c12.1 22 19.4 0 19.4 0C1069.4 305.4 475 276 475 276z"
            p-id="81480"
            fill="#5a5f69"
          ></path>
        </svg>
      </span>
      <div style="flex: 1">编辑</div>

      <div
        v-if="isUserPm"
        @click="toList"
        style="
          font-weight: bold;
          height: 44px;
          position: absolute;
          display: flex;
          right: 20px;
        "
      >
        <svg
          t="1657614714079"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          p-id="21156"
          width="30"
          height="30"
        >
          <path
            d="M136 256m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z"
            fill="#999999"
            p-id="21157"
          ></path>
          <path
            d="M136 512m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z"
            fill="#999999"
            p-id="21158"
          ></path>
          <path
            d="M136 768m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z"
            fill="#999999"
            p-id="21159"
          ></path>
          <path
            d="M296 296.43h591.17c22.45 0 41.27-18.37 40.82-40.82a40 40 0 0 0-40-39.18H296.83c-22.45 0-41.27 18.37-40.82 40.82A40 40 0 0 0 296 296.43zM888 472.21H296.83c-22.45 0-41.27 18.38-40.82 40.82a40 40 0 0 0 40 39.18h591.16c22.45 0 41.27-18.37 40.82-40.81A40 40 0 0 0 888 472.21zM888 728H296.83c-22.45 0-41.27 18.37-40.82 40.82A40 40 0 0 0 296 808h591.17c22.45 0 41.27-18.37 40.82-40.82A40 40 0 0 0 888 728z"
            fill="#999999"
            p-id="21160"
          ></path>
        </svg>
      </div>
    </div>

    <div v-show="isUserPm">
      <div style="padding-bottom: 10px">
        <div class="list" style="">
          <div class="listTitle">标题:</div>
          <div style="flex: 2">
            <input
              class="userInfoinput"
              type="text"
              style="width: 90%"
              name="capt"
              placeholder="请输入标题"
              v-model="editObj.title"
            />
          </div>
        </div>

        <div class="list">
          <div class="listTitle">内容链接:</div>
          <div style="flex: 2">
            <input
              class="userInfoinput"
              type="text"
              style="width: 90%"
              name="capt"
              placeholder="请输入内容链接"
              v-model="editObj.contentLink"
            />
          </div>
        </div>

        <div class="list">
          <div class="listTitle">关键字1:</div>
          <div style="flex: 2">
            <input
              class="userInfoinput"
              type="text"
              style="width: 90%"
              name="capt"
              placeholder="关键字1"
              v-model="keyword1"
            />
          </div>
        </div>
        <div class="list">
          <div class="listTitle">关键字2:</div>
          <div style="flex: 2">
            <input
              class="userInfoinput"
              type="text"
              style="width: 90%"
              name="capt"
              placeholder="关键字2"
              v-model="keyword2"
            />
          </div>
        </div>
        <!-- <div class="list">
          <div class="listTitle">关键字3:</div>
          <div style="flex: 2">
            <input
              class="userInfoinput"
              type="text"
              style="width: 90%"
              name="capt"
              placeholder="关键字3"
              v-model="keyword3"
            />
          </div>
        </div> -->

        <div class="list">
          <div class="listTitle">媒体名:</div>
          <div style="flex: 2">
            <input
              class="userInfoinput"
              type="text"
              style="width: 90%"
              name="capt"
              placeholder="媒体名"
              v-model="editObj.mediaName"
            />
          </div>
        </div>

        <div class="list">
          <div class="listTitle">涉及单位:</div>
          <div style="flex: 2">
            <input
              class="userInfoinput"
              type="text"
              style="width: 90%"
              name="capt"
              placeholder="涉及单位"
              v-model="editObj.mediaDeptName"
            />
          </div>
        </div>
        <div class="list">
          <div class="listTitle">轮值发布单位:</div>
          <div style="flex: 2">
            <input
              class="userInfoinput"
              type="text"
              style="width: 90%"
              name="capt"
              placeholder="轮值发布单位"
              v-model="editObj.pushUnit"
            />
          </div>
        </div>
        <div class="list">
          <div class="listTitle">
            是否为人民日报头版、央视《新闻联播》、新华全媒头条，如是请填写:
          </div>
          <div style="flex: 2">
            <RadioGroup
              v-model="editObj.personNews"
              type="button"
              button-style="solid"
            >
              <Radio label="是"></Radio>
              <Radio label="否"></Radio>
            </RadioGroup>
          </div>
        </div>
        <div class="list">
          <div class="listTitle">是否为一线人物报道</div>
          <div style="flex: 2">
            <RadioGroup
              v-model="editObj.oneLine"
              type="button"
              button-style="solid"
            >
              <Radio label="是"></Radio>
              <Radio label="否"></Radio>
            </RadioGroup>
          </div>
        </div>
        <div class="list" style="height: auto">
          <div class="listTitle">封面图</div>
          <div style="flex: 2; text-align: left">
            <div class="friendAvatar" @click="selImage">
              <i class="fa fa-plus"></i>
            </div>
            <!-- :src="editObj && editObj.img[0] ? editObj.img[0] : ''" -->

            <img
              ref="video"
              height="160px"
              width="auto"
              controls="controls"
              v-if="
                editObj &&
                editObj.img &&
                editObj.img[0] &&
                editObj.img[0].length > 0
              "
              :src="`https://gt-wx.gtrmt.cn/${editObj.img[0]}`"
            />
            <input
              type="file"
              accept="image/*"
              style="display: none"
              id="capture"
              ref="imgCapture"
              @change="onfilechange($event)"
              @select="onfilechange($event)"
            />
          </div>
        </div>
      </div>

      <div style="width: 100%; height: 1.5rem">
        <div
          v-show="startUpload"
          style="font-size: 1rem; color: red; text-align: center"
        >
          正在上传，请稍候......
        </div>
        <div
          style="font-size: 1rem; color: red; width: 100%; text-align: center"
        >
          {{ warning }}
        </div>
      </div>

      <div class="userInfoBtn">
        <Button type="info" @click="save" :disabled="showLoad">保存草稿</Button>
        <Button
          style="margin-left: 10px"
          @click="publishReview"
          :disabled="editObj.review !== '2'"
          :type="editObj.review !== '2' ? 'default' : 'info'"
          >提交审核</Button
        >
        <Button style="margin-left: 10px" @click="clearContent"
          >清空内容</Button
        >
      </div>
    </div>

    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
import debug from "debug";

import diaLogliu from "../../components/dialogLiu.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import noPassCom from "../../components/noPassCom.vue";

import ajax from "../../api/ajax";
import {
  wxConPost,
  getUserPm,
  getLocalStorage,
  getDefaultExpTime,
} from "../../api/httpApi";

const _d = debug("@pages:uploadVideos");
const content_SERVER = "https://content.gtrmt.cn:8603/";

export default {
  name: "tougaoEditor",
  data() {
    return {
      showButton: true,
      startUpload: false, //进度条是否显示
      uploadSuccess: false,
      description: "",
      imgSrc: "",
      // progress: '',
      warning: "",
      files: null,
      cardImg: {},
      file: "",
      path: "", //上传之后的视频路径

      showLoad: false,

      keyword1: "", // 关键字1
      keyword2: "", // 关键字2
      keyword3: "",

      editObj: {
        contentLink: "",
        mediaDeptName: "",
        mediaName: "",
        _cid: null, // 字段唯一标识
        created: null, // 创建时间戳
        updated: null, // 更新时间戳
        pushUnit: "",
        content: "", // 内容
        author: "", // 作者
        title: "", // 标题
        brief: "", // 简介
        column: { 0: "媒体看广铁" }, // 栏目， 如果存在则为 {0:'', 1: ''}, 数组形式
        video: null, // 第一个视频路径
        singleVideo: false, // 是否为单个视频
        review: "2", // '0':未审核(提交审核) '-1':审核未通过 '1':审核通过 '2'未提交审核
        isJingxuan: null, // '0':未审核 '-1':精选未通过 '1':精选审核通过 null：非精选栏目
        isSwiper: false, // false :未轮播 true: 轮播
        isTop: false, // false :未置顶 true: 置顶
        colnumTop: false, // 栏目 false :未置顶 true:置顶
        disable: false, // false:启用 true:禁用
        jxDisable: false, // 增加精选禁用，与栏目禁用区别
        cover: "none", // --> coverList
        img: [
          // "http://gtrmt.cn/static/gtttueditor/resources/TVideos/f493c7d4ec0ed1749ffb4fee2b3a41fd.jpeg",
        ], // 前三张图片路径
        resourceList: [], // 该内容用到的资源
        keywords: [], // 关键字
        isNotice: false, // 是否公告
        isCanShare: true, // 是否可以分享
        isUnOriginal: false, // 是否原创
        isMsgSend: false, // 是否推送
        stopCountStatus: false, // 是否停止收集阅读量
        isWaterMarker: false, // 是否设置水印，默认不设置
        superiorStatus: false, // 上进精神  默认不传达
        oneLine:false, // 一线报道
        personNews:false, // 人民日报
      },
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      quality: 0.7,
      name: "file",
      canvas: document.createElement("canvas"),

      isUserPm: true, // 是否有权限
    };
  },
  methods: {
    clearContent() {
      this.keyword1 = "";
      this.keyword2 = "";
      this.keyword3 = "";
      this.editObj = {
        contentLink: "",
        mediaDeptName: "",
        mediaName: "",
        _cid: null, // 字段唯一标识
        created: null, // 创建时间戳
        updated: null, // 更新时间戳
        content: "", // 内容
        author: "", // 作者
        title: "", // 标题
        brief: "", // 简介
        column: { 0: "媒体看广铁" }, // 栏目， 如果存在则为 {0:'', 1: ''}, 数组形式
        video: null, // 第一个视频路径
        singleVideo: false, // 是否为单个视频
        review: "2", // '0':未审核(提交审核) '-1':审核未通过 '1':审核通过 '2'未提交审核
        isJingxuan: null, // '0':未审核 '-1':精选未通过 '1':精选审核通过 null：非精选栏目
        isSwiper: false, // false :未轮播 true: 轮播
        isTop: false, // false :未置顶 true: 置顶
        colnumTop: false, // 栏目 false :未置顶 true:置顶
        disable: false, // false:启用 true:禁用
        jxDisable: false, // 增加精选禁用，与栏目禁用区别
        cover: "none", // --> coverList
        img: [
          // "http://gtrmt.cn/static/gtttueditor/resources/TVideos/f493c7d4ec0ed1749ffb4fee2b3a41fd.jpeg",
        ], // 前三张图片路径
        resourceList: [], // 该内容用到的资源
        keywords: [], // 关键字
        isNotice: false, // 是否公告
        isCanShare: true, // 是否可以分享
        isUnOriginal: false, // 是否原创
        isMsgSend: false, // 是否推送
        stopCountStatus: false, // 是否停止收集阅读量
        isWaterMarker: false, // 是否设置水印，默认不设置
        superiorStatus: false, // 上进精神  默认不传达
      };

      const userData = getLocalStorage("statsUserData", getDefaultExpTime());
      this.editObj.mediaDeptName =
        userData["dept_2nd_name"] || userData["deptName"] || "";
    },
    back() {
      this.$router.push({ path: "/statsmain" });
    },
    toList() {
      this.$router.push({ path: "/tougaoList" });
    },

    // 选择视频
    selImage() {
      this.$refs.imgCapture.click();
    },
    compress(file, quality, callback) {
      if (!window.FileReader || !window.Blob) {
        return errorHandler("您的浏览器不支持图片压缩")();
      }

      var reader = new FileReader();

      var mimeType = "image/jpeg"; // png格式全转为jpg格式

      reader.onload = createImage;
      reader.onerror = errorHandler("图片读取失败！");
      reader.readAsDataURL(file);

      function createImage() {
        var dataURL = this.result;
        var image = new Image();
        image.onload = compressImage;
        image.onerror = errorHandler("图片加载失败");
        image.src = dataURL;
      }
      const _self = this;

      function compressImage() {
        var canvas = _self.canvas;
        var ctx;
        var dataURI;
        var result;

        let _w = 750;
        let _h = 422;

        let w = 0;
        let h = 0;
        // 获取缩略图
        if (this.naturalWidth > (this.naturalHeight * 16) / 9) {
          h = this.naturalHeight;
          w = (h * 16) / 9;
        } else {
          // 以宽为基准
          w = this.naturalWidth;
          h = (w / 16) * 9;
        }
        console.log("_w-_h---", _w, _h);
        canvas.width = _w;
        canvas.height = _h;
        console.log("canvas-_h---", _w, _h);
        ctx = canvas.getContext("2d");
        ctx.drawImage(this, 0, 0, w, h, 0, 0, _w, _h);

        dataURI = canvas.toDataURL(mimeType, quality);
        result = dataURIToBlob(dataURI);

        callback(null, result);
      }

      function dataURIToBlob(dataURI) {
        var type = dataURI.match(/data:([^;]+)/)[1];
        var base64 = dataURI.replace(/^[^,]+,/, "");
        var byteString = atob(base64);

        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: type });
      }

      function errorHandler(message) {
        return function () {
          var error = new Error("Compression Error:", message);
          callback(error, null);
        };
      }
    },
    submitAjax(file, fileData, _postData) {
      //视频上传
      ajax({
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        withCredentials: false,
        // file, //视频文件
        file: fileData || file,
        data: _postData, //参数(要存储的路径以及文件夹名)
        filename: "file",
        // 请求发到生产平台
        action: `${content_SERVER}/services/gttt-content-mgt/resource/upload`,
        onProgress: (e) => {
          console.log("正在上传", e);
          this.startUpload = true;
        },
        onSuccess: (res) => {
          // encoding: "7bit"
          // filename: "f493c7d4ec0ed1749ffb4fee2b3a41fd"
          // mimetype: "image/jpeg"
          // originalname: "300 (2).jpeg"
          // path: "/static/gtttueditor/resources/TVideos/f493c7d4ec0ed1749ffb4fee2b3a41fd.jpeg"
          // 缩略图

          this.startUpload = false;
          this.showButton = false;
          this.uploadSuccess = true;
          this.warning = "上传成功！";
          setTimeout(() => {
            this.warning = "";
          }, 3000);
          this.editObj.img = [res.path];
        },
        onError: (err, response) => {
          _d("失败了吗", err, response);
          this.startUpload = false;
          this.warning = "上传失败，请重试......";
          setTimeout(() => {
            this.warning = "";
          }, 3000);
        },
      });
    },
    onfilechange(e) {
      this.files = this.$refs.imgCapture.files;
      this.files =
        e.target.files || e.dataTransfer.files || this.$refs.imgCapture.files;
      var _fileType = this.files[0].type.split("/");
      _d("_fileType======", _fileType, this.files[0].type);

      var CompressTypes = ["png", "jpg", "gif", "jpeg"];
      // format: ["jpg", "jpeg", "png", "gif", "svg"],
      console.log("eeeeeeee", e.target.files[0], _fileType[1]);
      if (!this.files.length) return;
      if (this.files[0].size < 52428800) {
        _d("_fileType======", _fileType[1]);
        if (CompressTypes.indexOf(_fileType[1]) > -1) {
          var reader = new FileReader();
          this.file = this.files[0];

          const _data = { path: "tougaoCover" }; // 要存放的路径
          const _postData = _.clone(_data); //接口参数
          _postData.fileName = this.file.name; //文件的名称

          // 处理图片
          let formData = new FormData();
          this.compress(this.file, this.quality, (err, data) => {
            if (err) {
              // 图片压缩处理异常
              return false;
            }
            formData.append(this.name, data);
            this.submitAjax(this.file, data, _postData);
          });

          // reader.onload = (e) => {
          //   this.imgSrc = e.target.result;
          //   //视频上传
          //   ajax({
          //     headers: {},
          //     withCredentials: false,
          //     file: this.file, //视频文件
          //     data: _postData, //参数(要存储的路径以及文件夹名)
          //     filename: "file",
          //     // 请求发到生产平台
          //     action: `${content_SERVER}/services/gttt-content-mgt/resource/upload`,
          //     onProgress: (e) => {
          //       _d("正在上传", e);
          //       this.startUpload = true;
          //     },
          //     onSuccess: (res) => {
          //       // encoding: "7bit"
          //       // filename: "f493c7d4ec0ed1749ffb4fee2b3a41fd"
          //       // mimetype: "image/jpeg"
          //       // originalname: "300 (2).jpeg"
          //       // path: "/static/gtttueditor/resources/TVideos/f493c7d4ec0ed1749ffb4fee2b3a41fd.jpeg"
          //       // 缩略图

          //       this.startUpload = false;
          //       this.showButton = false;
          //       this.uploadSuccess = true;
          //       this.warning = "上传成功！";
          //       setTimeout(() => {
          //         this.warning = "";
          //       }, 3000);
          //       this.editObj.img = [res.path];
          //     },
          //     onError: (err, response) => {
          //       _d("失败了吗", err, response);
          //       this.startUpload = false;
          //       this.warning = "上传失败，请重试......";
          //       setTimeout(() => {
          //         this.warning = "";
          //       }, 3000);
          //     },
          //   });
          // };
          reader.readAsDataURL(this.file);
        } else {
          this.warning = "格式受限！";
          setTimeout(() => {
            this.warning = "";
          }, 5000);
        }
      } else {
        this.warning = "超过限制，请上传50M以下的资源！";
        setTimeout(() => {
          this.warning = "";
        }, 5000);
      }
    },

    diaLogFun(msg) {
      this.dailogshow = true;
      this.dailogoptions.content = msg;
      this.dailogoptions.textColor = "white";
      setTimeout(() => {
        this.dailogshow = false;
      }, 2000);
    },

    // 保存草稿
    async save() {
      if (_.isEmpty(this.editObj.title)) {
        this.diaLogFun("标题不能为空");

        return;
      }
      if (_.isEmpty(this.editObj.contentLink)) {
        this.diaLogFun("链接不能为空");
        return;
      }
      if (_.isEmpty(this.editObj.mediaDeptName)) {
        this.diaLogFun("涉及单位不能为空");
        return;
      }
      if (_.isEmpty(this.editObj.mediaName)) {
        this.diaLogFun("媒体名不能为空");
        return;
      }

      this.showLoad = true;
      debug("save:", this.editObj);

      // 获取本次内容所用到的资源
      this.editObj.resourceList = this.editObj.img;

      this.editObj.keywords = [];
      // 设置关键字
      if (this.keyword1) {
        this.editObj.keywords[0] = this.keyword1;
      }
      if (this.keyword2) {
        this.editObj.keywords[1] = this.keyword2;
      }
      if (this.keyword3) {
        this.editObj.keywords[2] = this.keyword3;
      }

      debug("==========--------------", this.editObj.singleVideo, this.editObj);
      try {
        console.log("----", this.editObj);
        const ret = await wxConPost(
          "/services/gttt-content-mgt/tougao/updateContent",
          this.editObj
        );
        this.showLoad = false;
        this.editObj = ret.value; // 提交审核状态
        this.diaLogFun("保存成功");
      } catch (error) {
        console.log("save ERR:", error);
        this.showLoad = false;
        this.diaLogFun("保存失败");
      }
    },
    // 提交审核
    async publishReview() {
      try {
        // 先提交保存
        await this.save();
        // 设置提交审核状态
        await wxConPost("/services/gttt-content-mgt/tougao/publishReview", {
          _cid: this.editObj._cid,
        });
        this.editObj.review = "1"; // 提交审核状态
        this.diaLogFun("提交成功");
      } catch (e) {
        this.diaLogFun("提交失败");
      }
    },
  },
  async created() {
    const userPm = getUserPm("稿件管理");
    if (userPm) {
      if (userPm && (userPm.bContentTouGao || userPm.pmSuper)) {
        this.isShowDept = true;
        const _cid = this.$route.query.cid;

        if (_cid) {
          try {
            const result = await wxConPost(
              "/services/gttt-content-mgt/zd/getDetail",
              {
                _cid,
              }
            );
            if (result.ret) {
              this.editObj = result.ret;

              if (this.editObj.keywords) {
                this.keyword1 = this.editObj.keywords[0]
                  ? this.editObj.keywords[0]
                  : "";
                this.keyword2 = this.editObj.keywords[1]
                  ? this.editObj.keywords[1]
                  : "";
                this.keyword3 = this.editObj.keywords[2]
                  ? this.editObj.keywords[2]
                  : "";
              } else {
                this.keyword1 = "";
                this.keyword2 = "";
                this.keyword3 = "";
              }
              this.editObj.keywords = [
                this.keyword,
                this.keyword2,
                this.keyword3,
              ];

              if (_.isEmpty(this.editObj.isSwiper)) {
                this.editObj.isSwiper = false;
              }
            }
          } catch (e) {}
        }
        if (!this.editObj.mediaDeptName) {
          const userData = getLocalStorage(
            "statsUserData",
            getDefaultExpTime()
          );
          this.editObj.mediaDeptName =
            userData["dept_2nd_name"] || userData["deptName"] || "";
        }
      } else {
        this.isUserPm = false;
      }
    } else {
      this.isUserPm = false;
    }
  },
  components: { diaLogliu, loadingCmp, noPassCom },
  beforeCreate() {
    document.title = "媒体看广铁";
  },
};
</script>
